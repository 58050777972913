.single__course__item {
  padding: 15px;
}

.course__img img {
  border-radius: 10px;
  margin-bottom: 20px;
}

.enroll a {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #824263;
}

.lesson,
.students,
.rating {
  font-size: 0.9rem;
  color: #0a2b1e;
  font-weight: 500;
}

.lesson i,
.students i,
.rating i {
  font-weight: 400;
}

.course__title {
  line-height: 2rem;
}

.course__top__left h2 {
  font-weight: 600;
}
