.testimonial__content h2 {
  font-weight: 600;
}

.testimonial__img {
  width: 45%;
}

.testimonial__img img {
  width: 100%;
}

@media (max-width: 800px) {
  .d-flex {
    /* flex-direction: column; */
    margin: 0;
  }

  .testimonial.d-flex,
  .footer .d-flex {
    flex-direction: column;
  }

  .testimonial__content.w-50 {
    width: 80% !important;
    margin: auto;
  }

  .testimonial__img {
    width: 100%;
    margin-bottom: 2rem;
  }

  .testimonial__img img {
    border-radius: 10px;
  }
}
