.addcertificate {
  width: 70%;
  margin: auto;
}

.addcertificate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addcertificate__header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.addcertificate .loginInput {
  width: 500px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 10px;
  outline-color: #824263;
}

.email {
  margin-bottom: 10px;
}

@media (max-width: 800px) {
  .addcertificate .loginInput {
    width: 300px;
  }
}
