.footer {
  background: #f8f8f8;
}

.follows span a {
  text-decoration: none;
}

.follows span {
  margin-right: 15px;
}

.link__list .link__item {
  background: transparent;
}

.link__list a {
  text-decoration: none;
  color: #0a2b1e;
  font-weight: 500;
}

.footer a:hover {
  color: #824263;
}

@media (max-width: 800px) {
  .footer__logo {
    width: 45%;
    margin: auto;
  }

  .footer {
    text-align: center;
  }
}
