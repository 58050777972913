.login {
  margin: auto;
  text-align: center;
  /* background-color: var(--grey); */
  /* background: linear-gradient(
          to bottom,
          rgba(27, 33, 26, 0.501),
          rgba(3, 4, 3, 0.775)
        ),
        url("../../assets/images/explore4.webp"); */

  background-size: cover;
  /* margin-bottom: 3rem; */
  display: flex;
  border-bottom: 1px solid rgb(70, 70, 70);
  padding-bottom: 1rem;
}

.login__container {
  background-color: white;
  text-align: center;
  padding-top: 2rem;
  width: 40%;
  margin: auto;
  margin-top: 1rem;
  box-shadow: 2px 10px var(--grey);
  height: 25rem;
}

.login__container__form {
  text-align: center;
  background-color: white;
}

.login__container__form > h4 {
  text-align: center;
  font-size: 2rem;
  text-transform: capitalize;
  color: var(--purple);
  font-family: var(--rubik);
  margin-bottom: 1rem;
}

.login__container__form form > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__container__form div input {
  min-width: 20rem;
  height: 2rem;
  margin-bottom: 1rem;
  outline: none;
  padding: 0px 10px;
}

.login__container__form button {
  width: 10rem;
  padding: 10px;
  color: white;
  background-color: var(--purple);
  border: none;
  font-size: 1rem;
  text-transform: capitalize;
  cursor: pointer;
}

.login__container__form__create {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.login__container__form__create h5 {
  font-family: var(--rubik);
  margin-right: 0.5rem;
  font-size: 14px;
}

.login__container__form a {
  font-family: var(--rubik);
  font-size: 14px;
  color: var(--purple);
}

.login .button {
  width: 20%;
}

.login__container__btn {
  background-color: #824263;
  color: white;
  border-radius: 10px;
  width: 40%;
  margin: auto;
  padding: 10px;
  cursor: pointer;
}

.login__container__btn:hover {
  opacity: 0.9;
}

/* .header {
      display: none;
    } */

@media (max-width: 800px) {
  /* .header__mobile,
      footer {
        display: none;
      } */

  .login__container {
    width: 90%;
  }

  .login__container__form div input {
    min-width: 15rem;
  }
}
