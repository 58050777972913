.certificate {
  width: 60%;
  margin: 3rem auto;
  background-color: rgb(253, 253, 253);
  padding: 2rem;
  border: 2px solid #824263;
  position: relative;
}

.certificate__header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #824263;
}

.certificate__header img {
  width: 50%;
}

.certificate__header__name {
  font-size: 18px;
  font-weight: bolder;
  text-transform: capitalize;
  margin: 2rem 0;
}

.certificate__body {
  margin: 2rem 0;
}

.certificate__body__date {
  font-weight: bolder;
  text-align: right;
  margin: 2rem;
}

.certificate__body__header h3 {
  text-align: center;
  letter-spacing: 0.2rem;
  margin: 2rem;
  font-weight: bolder;
  font-size: 30px;
  text-transform: uppercase;
  text-decoration: underline;
  color: #824263;
}

.certificate__body__content {
  margin: 2rem;
  font-size: 16px;
  line-height: 2rem;
}

.certificate__body__content p::first-letter {
  font-weight: bolder;
}

.certificate__body__content span {
  font-weight: bolder;
  text-transform: capitalize;
  color: #824263;
}

.certificate__footer {
  margin: 1rem;
  display: flex;
  justify-content: space-between;
}

.certificate__footer__registrar {
  font-weight: bolder;
}

.certificate__footer p {
  font-size: 14px;
}

.certificate__body__content p {
  font-weight: bold;
  color: black;
}

.certificate__token {
  color: #824263;
  font-weight: bolder;
}

.dashboard .certificate__body__header h3 {
  letter-spacing: 0;
}

.center {
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 10px;
  color: #824263;
}

/* Watermark */
.certificate__watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.109);
  pointer-events: none;
  letter-spacing: 1.2rem;
}

@media (max-width: 800px) {
  .certificate {
    width: 100%;
    /* margin: 10px; */
    /* padding: 1rem; */
  }

  .dashboard__container__btn__showall.cert {
    width: 60%;
  }

  .certificate__header img {
    margin-left: 1rem;
  }
}
