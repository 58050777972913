@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Andada+Pro:wght@400;500;600;700;800&display=swap);
.header {
  width: 100%;
  height: 80px;
  line-height: 80px;
  padding: 1rem 0;
}

.nav__list {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  grid-column-gap: 1.3rem;
  column-gap: 1.3rem;
}

.nav__item a {
  font-weight: 500;
  font-size: 1.1rem;
  color: #0a2b1e;
  text-decoration: none;
  transition: 0.3s;
}

.nav__item a:hover {
  color: #824263;
}

.nav__right p {
  font-weight: 500;
  color: #0a2b1e;
  font-size: 1.1rem;
}

.nav__right p i {
  font-size: 1.3rem;
}

.mobile__menu span i {
  font-size: 1.4rem;
  color: #0a2b1e;
  cursor: pointer;
}

.mobile__menu {
  display: none;
}

.header__logo img {
  width: 50%;
}

@media only screen and (max-width: 991px) {
  .nav__menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0a2b1ec2;
    z-index: 99;
    display: none;
  }

  .nav__list {
    position: absolute;
    background: #fff;
    top: 0;
    right: 0;
    width: 250px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 999;
    padding-top: 80px;
  }

  .mobile__menu {
    display: block;
  }
  .nav__right {
    display: none;
  }

  .active__menu {
    display: block;
  }
}

.hero__content h2 {
  font-weight: 600;
  line-height: 55px;
}

.hero__content {
  padding-top: 80px;
}
.search input {
  border: none;
  outline: none;
  padding: 7px 10%;
}

.search {
  width: 60%;
  border: 1px solid #17bf9d44;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 50px;
  margin-top: 20px;
}

@media (max-width: 800px) {
  .search {
    width: 30%;
  }

  .hero__content {
    padding-top: 40px;
  }

  .hero__img {
    height: 60vh;
    width: 100%;
    text-align: center;
  }

  .hero__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.counter {
  font-size: 2rem;
  font-weight: 600;
  color: #0a2b1e;
}

.counter__title {
  font-size: 1rem;
  color: #0a2b1e;
  font-weight: 500;
}

.about__content {
  padding-left: 50px;
}

.about__img img {
  border-radius: 15px;
}

.about__content h2 {
  font-weight: 600;
}

@media (max-width: 800px) {
  .about__content h2 {
    margin-top: 3rem;
  }
}

.single__course__item {
  padding: 15px;
}

.course__img img {
  border-radius: 10px;
  margin-bottom: 20px;
}

.enroll a {
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  color: #824263;
}

.lesson,
.students,
.rating {
  font-size: 0.9rem;
  color: #0a2b1e;
  font-weight: 500;
}

.lesson i,
.students i,
.rating i {
  font-weight: 400;
}

.course__title {
  line-height: 2rem;
}

.course__top__left h2 {
  font-weight: 600;
}

.choose__img img {
  border-radius: 15px;
}

.choose__content {
  padding-right: 50px;
}

.choose__content h2 {
  font-weight: 600;
}

.choose__img {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 777;
}

.play__icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #fff;
  z-index: 7777;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.play__icon i {
  color: #824263;
  font-size: 2rem;
  padding: 10px;
}

.single__feature h2 i {
  font-size: 2.5rem;
}

.single__feature h6 {
  font-weight: 600;
}

.free__course__img {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
}

.free__btn {
  position: absolute;
  bottom: -20px;
  right: 20px;
  z-index: 999999;
  padding: 4px 25px !important;
  font-size: 0.8rem;
}

.free__course__img img {
  border-radius: 10px;
}

.free__course__details h6 {
  font-weight: 600;
  line-height: 2rem;
}

.testimonial__content h2 {
  font-weight: 600;
}

.testimonial__img {
  width: 45%;
}

.testimonial__img img {
  width: 100%;
}

@media (max-width: 800px) {
  .d-flex {
    /* flex-direction: column; */
    margin: 0;
  }

  .testimonial.d-flex,
  .footer .d-flex {
    flex-direction: column;
  }

  .testimonial__content.w-50 {
    width: 80% !important;
    margin: auto;
  }

  .testimonial__img {
    width: 100%;
    margin-bottom: 2rem;
  }

  .testimonial__img img {
    border-radius: 10px;
  }
}

.newsletter {
  background: #824263;

  padding: 60px;
  border-radius: 15px;
}

.newsletter h2 {
  color: #fff;
}
.subscribe input {
  border: none;
  outline: none;
}

.subscribe {
  padding: 7px 10px;
  background: #fff;
  width: 45%;
  margin: auto;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 768px) {
  .newsletter {
    padding: 40px;
  }

  .subscribe {
    width: 70%;
  }
}

@media screen and (max-width: 480px) {
  .newsletter {
    padding: 30px;
  }

  .subscribe {
    width: 90%;
  }
}

.footer {
  background: #f8f8f8;
}

.follows span a {
  text-decoration: none;
}

.follows span {
  margin-right: 15px;
}

.link__list .link__item {
  background: transparent;
}

.link__list a {
  text-decoration: none;
  color: #0a2b1e;
  font-weight: 500;
}

.footer a:hover {
  color: #824263;
}

@media (max-width: 800px) {
  .footer__logo {
    width: 45%;
    margin: auto;
  }

  .footer {
    text-align: center;
  }
}

.login {
  margin: auto;
  text-align: center;
  /* background-color: var(--grey); */
  /* background: linear-gradient(
          to bottom,
          rgba(27, 33, 26, 0.501),
          rgba(3, 4, 3, 0.775)
        ),
        url("../../assets/images/explore4.webp"); */

  background-size: cover;
  /* margin-bottom: 3rem; */
  display: flex;
  border-bottom: 1px solid rgb(70, 70, 70);
  padding-bottom: 1rem;
}

.login__container {
  background-color: white;
  text-align: center;
  padding-top: 2rem;
  width: 40%;
  margin: auto;
  margin-top: 1rem;
  box-shadow: 2px 10px var(--grey);
  height: 25rem;
}

.login__container__form {
  text-align: center;
  background-color: white;
}

.login__container__form > h4 {
  text-align: center;
  font-size: 2rem;
  text-transform: capitalize;
  color: var(--purple);
  font-family: var(--rubik);
  margin-bottom: 1rem;
}

.login__container__form form > div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__container__form div input {
  min-width: 20rem;
  height: 2rem;
  margin-bottom: 1rem;
  outline: none;
  padding: 0px 10px;
}

.login__container__form button {
  width: 10rem;
  padding: 10px;
  color: white;
  background-color: var(--purple);
  border: none;
  font-size: 1rem;
  text-transform: capitalize;
  cursor: pointer;
}

.login__container__form__create {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.login__container__form__create h5 {
  font-family: var(--rubik);
  margin-right: 0.5rem;
  font-size: 14px;
}

.login__container__form a {
  font-family: var(--rubik);
  font-size: 14px;
  color: var(--purple);
}

.login .button {
  width: 20%;
}

.login__container__btn {
  background-color: #824263;
  color: white;
  border-radius: 10px;
  width: 40%;
  margin: auto;
  padding: 10px;
  cursor: pointer;
}

.login__container__btn:hover {
  opacity: 0.9;
}

/* .header {
      display: none;
    } */

@media (max-width: 800px) {
  /* .header__mobile,
      footer {
        display: none;
      } */

  .login__container {
    width: 90%;
  }

  .login__container__form div input {
    min-width: 15rem;
  }
}

.verify {
  width: 95%;
  margin: 3rem auto;
  text-align: center;
}

.verify__header {
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 30px;
}

.verify__verify__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verify__verify__container p input {
  width: 40rem;
  height: 3rem;
  outline: none;
  padding: 0 1rem;
  margin-top: 1rem;
  border: 1px solid rgb(183, 178, 178);
  border-radius: 5px;
}

.verify__verify__container .MuiSvgIcon-root {
  font-size: 40px;
  margin-top: 1.3rem;
  cursor: pointer;
}

@media (max-width: 800px) {
  .verify__verify__container p input {
    width: 15rem;
  }

  .verify__verify__container .MuiSvgIcon-root {
    font-size: 30px;
  }

  .verify__header {
    font-size: 20px;
  }
}

.certificate {
  width: 60%;
  margin: 3rem auto;
  background-color: rgb(253, 253, 253);
  padding: 2rem;
  border: 2px solid #824263;
  position: relative;
}

.certificate__header {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #824263;
}

.certificate__header img {
  width: 50%;
}

.certificate__header__name {
  font-size: 18px;
  font-weight: bolder;
  text-transform: capitalize;
  margin: 2rem 0;
}

.certificate__body {
  margin: 2rem 0;
}

.certificate__body__date {
  font-weight: bolder;
  text-align: right;
  margin: 2rem;
}

.certificate__body__header h3 {
  text-align: center;
  letter-spacing: 0.2rem;
  margin: 2rem;
  font-weight: bolder;
  font-size: 30px;
  text-transform: uppercase;
  text-decoration: underline;
  color: #824263;
}

.certificate__body__content {
  margin: 2rem;
  font-size: 16px;
  line-height: 2rem;
}

.certificate__body__content p::first-letter {
  font-weight: bolder;
}

.certificate__body__content span {
  font-weight: bolder;
  text-transform: capitalize;
  color: #824263;
}

.certificate__footer {
  margin: 1rem;
  display: flex;
  justify-content: space-between;
}

.certificate__footer__registrar {
  font-weight: bolder;
}

.certificate__footer p {
  font-size: 14px;
}

.certificate__body__content p {
  font-weight: bold;
  color: black;
}

.certificate__token {
  color: #824263;
  font-weight: bolder;
}

.dashboard .certificate__body__header h3 {
  letter-spacing: 0;
}

.center {
  font-size: 24px;
  font-weight: bolder;
  text-align: center;
  margin-bottom: 10px;
  color: #824263;
}

/* Watermark */
.certificate__watermark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(-45deg);
  font-size: 40px;
  font-weight: bold;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.109);
  pointer-events: none;
  letter-spacing: 1.2rem;
}

@media (max-width: 800px) {
  .certificate {
    width: 100%;
    /* margin: 10px; */
    /* padding: 1rem; */
  }

  .dashboard__container__btn__showall.cert {
    width: 60%;
  }

  .certificate__header img {
    margin-left: 1rem;
  }
}

.dashboard__header {
  text-align: center;
  font-weight: bolder;
  font-size: 24px;
  text-transform: capitalize;
}

.dashboard__recents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
}

.dashboard__cert__card {
  width: 40%;
  border: 2px solid #824263;
  margin: 2rem 1rem;
  cursor: pointer;
}

.dashboard__container__btn__add,
.dashboard__container__btn__showall {
  background-color: #824263;
  color: white;
  border-radius: 10px;
  width: 20%;
  margin: auto;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-transform: capitalize;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.dashboard a {
  color: white;
  text-decoration: none;
}

.dashboard a:hover {
  color: white;
}

@media (max-width: 800px) {
  .dashboard__recents {
    display: block;
  }

  .dashboard__cert__card {
    width: 100%;
    margin: 1rem 0;
  }

  .dashboard__container__btn__add,
  .dashboard__container__btn__showall {
    width: 40%;
    margin-bottom: 5rem;
  }
}

.allcertificates .certificate__body__header h3 {
  letter-spacing: 0;
}

.addcertificate {
  width: 70%;
  margin: auto;
}

.addcertificate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.addcertificate__header {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.addcertificate .loginInput {
  width: 500px;
  height: 40px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 10px;
  outline-color: #824263;
}

.email {
  margin-bottom: 10px;
}

@media (max-width: 800px) {
  .addcertificate .loginInput {
    width: 300px;
  }
}

/* =========== base style ========= */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

section,
footer {
  padding-top: 70px;
  padding-bottom: 70px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 2rem;
  line-height: 50px;
  font-family: "Poppins", sans-serif;
  color: #0a2b1e;
  font-weight: 600;
}

p {
  color: #596b65;
  font-size: 1rem;
  line-height: 35px;
  font-family: "Andada Pro", serif;
  font-weight: 400;
}

button.btn {
  background: #824263;
  color: #fff;
  padding: 7px 25px;
  border-radius: 50px;
}

i {
  color: #824263;
}

@media (max-width: 800px) {
  section,
  footer {
    padding-top: 80px;
    padding-bottom: 0px;
  }
}

