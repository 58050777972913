.verify {
  width: 95%;
  margin: 3rem auto;
  text-align: center;
}

.verify__header {
  text-transform: capitalize;
  font-weight: bolder;
  font-size: 30px;
}

.verify__verify__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.verify__verify__container p input {
  width: 40rem;
  height: 3rem;
  outline: none;
  padding: 0 1rem;
  margin-top: 1rem;
  border: 1px solid rgb(183, 178, 178);
  border-radius: 5px;
}

.verify__verify__container .MuiSvgIcon-root {
  font-size: 40px;
  margin-top: 1.3rem;
  cursor: pointer;
}

@media (max-width: 800px) {
  .verify__verify__container p input {
    width: 15rem;
  }

  .verify__verify__container .MuiSvgIcon-root {
    font-size: 30px;
  }

  .verify__header {
    font-size: 20px;
  }
}
