.dashboard__header {
  text-align: center;
  font-weight: bolder;
  font-size: 24px;
  text-transform: capitalize;
}

.dashboard__recents {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  margin: auto;
}

.dashboard__cert__card {
  width: 40%;
  border: 2px solid #824263;
  margin: 2rem 1rem;
  cursor: pointer;
}

.dashboard__container__btn__add,
.dashboard__container__btn__showall {
  background-color: #824263;
  color: white;
  border-radius: 10px;
  width: 20%;
  margin: auto;
  padding: 10px;
  cursor: pointer;
  text-align: center;
  text-transform: capitalize;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.dashboard a {
  color: white;
  text-decoration: none;
}

.dashboard a:hover {
  color: white;
}

@media (max-width: 800px) {
  .dashboard__recents {
    display: block;
  }

  .dashboard__cert__card {
    width: 100%;
    margin: 1rem 0;
  }

  .dashboard__container__btn__add,
  .dashboard__container__btn__showall {
    width: 40%;
    margin-bottom: 5rem;
  }
}
